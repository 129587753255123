"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tuple = exports.array = void 0;
const defs = require("../definitions");
const utils = require("../utils");
const codec_1 = require("./codec");
const array = (type) => {
    const assertion = (data) => {
        if (!Array.isArray(data)) {
            throw new utils.TransformError(`Expected an array but got ${typeof data}`);
        }
    };
    return (0, codec_1.codec)(defs.CodecType.Array, (data) => {
        assertion(data);
        return data.map(type.encode);
    }, (data) => {
        assertion(data);
        return data.map(type.decode);
    }, {
        type
    });
};
exports.array = array;
const tuple = (tuple) => {
    const transformer = (transformation) => (data) => {
        if (!Array.isArray(data)) {
            throw new utils.TransformError(`Expected an array but got ${typeof data}`);
        }
        if (data.length !== tuple.length) {
            throw new utils.TransformError(`Given tuple does not match schema. Length mismatch ${tuple.length} !== ${data.length}`);
        }
        return tuple.map((codec, i) => {
            return codec[transformation](data[i]);
        });
    };
    return (0, codec_1.codec)(defs.CodecType.Tuple, transformer('encode'), transformer('decode'), {
        codecs: tuple
    });
};
exports.tuple = tuple;
