"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.partial = exports.omit = void 0;
const defs = require("../definitions");
const maps = require("./maps");
const c = require("./codec");
const omit = (codec, mask) => {
    const omitFromObjectCodec = (codec) => {
        const entries = Object.entries(codec.props.shape).filter(([key]) => {
            return !mask.includes(key);
        });
        return maps.object(Object.fromEntries(entries));
    };
    const omitFromIntersectionCodec = (codec) => {
        const codecs = codec.props.codecs.map(omitMaskFromCodec);
        return c.codec(defs.CodecType.Intersection, c.createIntersectionTransformer('encode', codecs), c.createIntersectionTransformer('decode', codecs), {
            codecs
        });
    };
    const omitFromUnionCodec = (codec) => {
        const codecs = codec.props.codecs.map(omitMaskFromCodec);
        return c.codec(defs.CodecType.Union, c.createUnionTransformer('encode', codecs), c.createUnionTransformer('decode', codecs), {
            codecs
        });
    };
    const omitMaskFromCodec = (codec) => {
        switch (codec._tag) {
            case defs.CodecType.Object: {
                return omitFromObjectCodec(codec);
            }
            case defs.CodecType.Intersection: {
                return omitFromIntersectionCodec(codec);
            }
            case defs.CodecType.Union: {
                return omitFromUnionCodec(codec);
            }
            default: {
                throw new Error(`Unsupported codec ${codec._tag}`);
            }
        }
    };
    return omitMaskFromCodec(codec);
};
exports.omit = omit;
const partial = (codec) => {
    const partialObjectCodec = (codec) => {
        const entries = Object.entries(codec.props.shape).map(([key, codec]) => {
            return [key, codec.optional()];
        });
        return maps.object(Object.fromEntries(entries));
    };
    const partialIntersectionCodec = (codec) => {
        const codecs = codec.props.codecs.map(createPartialCodec);
        return c.codec(defs.CodecType.Intersection, c.createIntersectionTransformer('encode', codecs), c.createIntersectionTransformer('decode', codecs), {
            codecs
        });
    };
    const partialUnionCodec = (codec) => {
        const codecs = codec.props.codecs.map(createPartialCodec);
        return c.codec(defs.CodecType.Union, c.createUnionTransformer('encode', codecs), c.createUnionTransformer('decode', codecs), {
            codecs
        });
    };
    const createPartialCodec = (codec) => {
        switch (codec._tag) {
            case defs.CodecType.Object: {
                return partialObjectCodec(codec);
            }
            case defs.CodecType.Intersection: {
                return partialIntersectionCodec(codec);
            }
            case defs.CodecType.Union: {
                return partialUnionCodec(codec);
            }
        }
    };
    return createPartialCodec(codec);
};
exports.partial = partial;
