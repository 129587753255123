"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCodecType = exports.TransformError = void 0;
class TransformError extends Error {
    constructor(errors) {
        super(Array.isArray(errors) ? errors.join(', ') : errors);
        this.errors = errors;
        this.name = 'TransformError';
    }
}
exports.TransformError = TransformError;
function isCodecType(codec, type) {
    return codec._tag === type;
}
exports.isCodecType = isCodecType;
