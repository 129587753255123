import * as c from "ts-codec"

type SummarizeRequestParams = {
	url: string
}

export const summarizeRequestParams: c.Codec<
	SummarizeRequestParams,
	SummarizeRequestParams
> = c.object({
	url: c.string,
})

export type SummaryNode = {
	index: number
	content: string
	children: number[]
}
export type SummaryOutline = {
	rootNodeIndex: number
	nodes: SummaryNode[]
}

const summaryNode: c.Codec<SummaryNode, SummaryNode> = c.object({
	index: c.number,
	content: c.string,
	children: c.array(c.number),
})

export const summaryOutline: c.Codec<SummaryOutline, SummaryOutline> = c.object(
	{
		rootNodeIndex: c.number,
		nodes: c.array(summaryNode),
	}
)
