"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateJSONSchema = void 0;
const defs = require("./definitions");
const p = require("./parsers");
const generateJSONSchema = (codec, options) => {
    const parsers = [
        ...(options?.parsers || []),
        p.AnyParser,
        p.StringParser,
        p.NumberParser,
        p.BooleanParser,
        p.NullParser,
        p.LiteralParser,
        p.EnumParser,
        p.ObjectParser,
        p.RecordParser,
        p.ArrayParser,
        p.TupleParser,
        p.IntersectionParser,
        p.UnionParser,
        p.RecursiveParser
    ];
    const recursion_cache = new Map();
    const schema = p.RootParser(codec, {
        parsers,
        target: options?.target ?? defs.TransformTarget.Encoded,
        allowAdditional: options?.allowAdditional ?? false,
        cache: recursion_cache
    });
    return {
        definitions: Object.fromEntries(recursion_cache.entries()),
        ...schema
    };
};
exports.generateJSONSchema = generateJSONSchema;
