"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recursive = void 0;
const defs = require("../definitions");
const codec_1 = require("./codec");
const recursive = (id, resolver) => {
    return (0, codec_1.codec)(defs.CodecType.Recursive, (data) => {
        const codec = resolver();
        return codec.encode(data);
    }, (data) => {
        const codec = resolver();
        return codec.decode(data);
    }, {
        id: id,
        resolver: resolver
    });
};
exports.recursive = recursive;
