"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Enum = exports.any = exports.Null = exports.literal = exports.number = exports.boolean = exports.string = exports.identityCodec = void 0;
const defs = require("../definitions");
const utils = require("../utils");
const codec_1 = require("./codec");
const identityCodec = (type) => {
    const transform = (data) => {
        if (typeof data !== type) {
            throw new utils.TransformError(`type must be ${type}, received ${typeof data}`);
        }
        return data;
    };
    return (0, codec_1.codec)(type, transform, transform);
};
exports.identityCodec = identityCodec;
exports.string = (0, exports.identityCodec)(defs.CodecType.String);
exports.boolean = (0, exports.identityCodec)(defs.CodecType.Boolean);
exports.number = (0, exports.identityCodec)(defs.CodecType.Number);
const literal = (literal) => {
    const transform = (data) => {
        if (data !== literal) {
            throw new utils.TransformError(`Expected '${literal}' but go '${data}'`);
        }
        return data;
    };
    return (0, codec_1.codec)(defs.CodecType.Literal, transform, transform, {
        value: literal
    });
};
exports.literal = literal;
const assertNull = (data) => {
    if (data !== null) {
        throw new utils.TransformError(`expected value to be null`);
    }
    return null;
};
exports.Null = (0, codec_1.codec)(defs.CodecType.Null, assertNull, assertNull);
exports.any = (0, codec_1.codec)(defs.CodecType.Any, (data) => data, (data) => data);
const Enum = (native_enum) => {
    const values = Object.values(native_enum);
    const transformer = (data) => {
        if (!values.includes(data)) {
            throw new utils.TransformError(`Expected ${data} to match one of ${values}`);
        }
        return data;
    };
    return (0, codec_1.codec)(defs.CodecType.Enum, transformer, transformer, {
        enum: native_enum
    });
};
exports.Enum = Enum;
