import { useState } from "react"
import ReactDOM from "react-dom/client"
import { SummaryOutline, summaryOutline } from "./codec"

const workerUrl =
	process.env.NODE_ENV === "production"
		? "https://outsmart.tklightningfast.workers.dev"
		: "http://localhost:3000"

// async function fetchHTMLDocument(url: string) {
// 	const res = await fetch(url)
// 	const html = await res.text()
// 	const parser = new DOMParser()
// 	const doc = parser.parseFromString(html, "text/html")
// 	return doc
// }

// function isDefined<T>(value: T | undefined | null): value is T {
// 	return value !== undefined && value !== null
// }

// function getContentBlocks(root: Document): string[] {
// 	const paragraphs = uniq(
// 		flatten([
// 			Array.from(root.querySelectorAll("main p")),
// 			Array.from(root.querySelectorAll("main div")),
// 			Array.from(root.querySelectorAll("article div")),
// 			Array.from(root.querySelectorAll("article p")),
// 		])
// 	)

// 	const contentBlocks = paragraphs
// 		.map((paragraph) => paragraph.textContent)
// 		.filter(isDefined)
// 		.filter((content) => content !== "")

// 	return contentBlocks
// }

async function getSummaryOutline(url: string): Promise<SummaryOutline> {
	// const doc = await fetchHTMLDocument(url)
	// const content = getContentBlocks(doc)

	const response = await fetch(workerUrl, {
		method: "POST",
		body: JSON.stringify({ url }),
	})

	if (response.status !== 200) {
		throw new Error(await response.text())
	}

	const json = await response.json()
	const outline = summaryOutline.decode(json)

	return outline
}

function SummaryNodeView(props: {
	indent: number
	outline: SummaryOutline
	index: number
}) {
	const { index, indent, outline } = props
	const node = outline.nodes[index]
	if (node.children.length === 0) {
		return (
			<blockquote style={{ paddingLeft: `${2 * indent}em` }}>
				{node.content}
			</blockquote>
		)
	}

	return (
		<details style={{ padding: "8px 0" }}>
			<summary style={{ paddingLeft: `${2 * indent}em` }}>
				{node.content}
			</summary>
			{node.children.map((index) => (
				<SummaryNodeView indent={indent + 1} outline={outline} index={index} />
			))}
		</details>
	)
}

function SummaryOutlineView(props: { outline: SummaryOutline }) {
	const { outline } = props

	return (
		<SummaryNodeView
			outline={outline}
			indent={0}
			index={outline.rootNodeIndex}
		/>
	)
}

function App() {
	const [url, setUrl] = useState<string>("")
	const [outline, setOutline] = useState<SummaryOutline>()
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string>("")

	const sendRequest = (event: React.FormEvent) => {
		event.preventDefault()
		if (url === "") return

		setLoading(true)
		setError("")

		getSummaryOutline(url)
			.then(setOutline)
			.catch((error) => {
				console.error(error)
				setError(`${error}`)
			})
			.finally(() => setLoading(false))
	}

	return (
		<>
			<form onSubmit={sendRequest}>
				<input
					style={{ width: 400 }}
					aria-label="URL"
					value={url}
					onChange={(event) => setUrl(event.target.value)}
				/>
				<button type="submit">Send request</button>
			</form>
			{loading && <p>Loading.</p>}
			{error && <p>{error}</p>}
			{outline && <SummaryOutlineView outline={outline} />}
		</>
	)
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />)
