"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TupleParser = exports.ArrayParser = void 0;
const defs = require("../../definitions");
const utils_1 = require("../utils");
const root = require("./root");
exports.ArrayParser = (0, utils_1.createParser)(defs.CodecType.Array, (codec, options) => {
    return {
        type: 'array',
        items: root.RootParser(codec.props.type, options)
    };
});
exports.TupleParser = (0, utils_1.createParser)(defs.CodecType.Tuple, (codec, options) => {
    return {
        type: 'array',
        items: codec.props.codecs.map((codec) => root.RootParser(codec, options)),
        minItems: codec.props.codecs.length,
        maxItems: codec.props.codecs.length
    };
});
