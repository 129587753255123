"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootParser = void 0;
const RootParser = (codec, options) => {
    const parser = options.parsers.find((parser) => parser.tag === codec._tag);
    if (!parser) {
        throw new Error(`No parser configured for codec ${codec._tag}`);
    }
    const schema = parser.parse(codec, options);
    if (codec.props.metadata?.description) {
        return {
            description: codec.props.metadata.description,
            ...schema
        };
    }
    return schema;
};
exports.RootParser = RootParser;
