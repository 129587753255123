"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.optional = exports.union = exports.createUnionTransformer = exports.intersection = exports.createIntersectionTransformer = exports.codec = void 0;
const defs = require("../definitions");
const utils = require("../utils");
const codec = (tag, encode, decode, props) => {
    const c = {
        _tag: tag,
        props: {
            ...(props || {}),
            metadata: props?.metadata ?? {},
            required: props?.required ?? true
        },
        encode,
        decode,
        and: (extention) => (0, exports.intersection)(c, extention),
        or: (extention) => (0, exports.union)(c, extention),
        meta: (metadata) => {
            return (0, exports.codec)(tag, encode, decode, {
                ...c.props,
                metadata: {
                    ...c.props.metadata,
                    ...metadata
                }
            });
        },
        optional: () => (0, exports.optional)(c)
    };
    return c;
};
exports.codec = codec;
const mergeSameCodecs = (tag, c1, c2) => {
    const codecs = [];
    if (utils.isCodecType(c1, tag)) {
        codecs.push(...c1.props.codecs);
    }
    else {
        codecs.push(c1);
    }
    if (utils.isCodecType(c2, tag)) {
        codecs.push(...c2.props.codecs);
    }
    else {
        codecs.push(c2);
    }
    return codecs;
};
const createIntersectionTransformer = (op, codecs) => (data) => {
    return codecs.reduce((acc, codec) => {
        return {
            ...acc,
            ...codec[op](data)
        };
    }, {});
};
exports.createIntersectionTransformer = createIntersectionTransformer;
const intersection = (c1, c2) => {
    const codecs = mergeSameCodecs(defs.CodecType.Intersection, c1, c2);
    return (0, exports.codec)(defs.CodecType.Intersection, (0, exports.createIntersectionTransformer)('encode', codecs), (0, exports.createIntersectionTransformer)('decode', codecs), {
        codecs: codecs
    });
};
exports.intersection = intersection;
const createUnionTransformer = (op, codecs) => (data) => {
    const errors = [];
    for (const codec of codecs) {
        try {
            return codec[op](data);
        }
        catch (err) {
            errors.push(err);
        }
    }
    throw new utils.TransformError(errors
        .map((error) => {
        if (error instanceof utils.TransformError) {
            return error.errors;
        }
        return error.toString();
    })
        .flat());
};
exports.createUnionTransformer = createUnionTransformer;
const union = (c1, c2) => {
    const codecs = mergeSameCodecs(defs.CodecType.Union, c1, c2);
    return (0, exports.codec)(defs.CodecType.Union, (0, exports.createUnionTransformer)('encode', codecs), (0, exports.createUnionTransformer)('decode', codecs), {
        codecs
    });
};
exports.union = union;
const optional = (type) => {
    if (!type.props.required) {
        return type;
    }
    return (0, exports.codec)(type._tag, (data) => {
        if (data === undefined) {
            return undefined;
        }
        return type.encode(data);
    }, (data) => {
        if (data === undefined) {
            return undefined;
        }
        return type.decode(data);
    }, {
        ...type.props,
        required: false
    });
};
exports.optional = optional;
