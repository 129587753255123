"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordParser = exports.ObjectParser = void 0;
const defs = require("../../definitions");
const utils_1 = require("../utils");
const root = require("./root");
exports.ObjectParser = (0, utils_1.createParser)(defs.CodecType.Object, (codec, options) => {
    const entries = Object.entries(codec.props.shape);
    return {
        type: 'object',
        properties: entries.reduce((acc, [key, codec]) => {
            acc[key] = root.RootParser(codec, options);
            return acc;
        }, {}),
        additionalProperties: !!options?.allowAdditional,
        required: entries
            .filter(([, codec]) => {
            return codec.props.required;
        })
            .map(([key]) => key)
    };
});
exports.RecordParser = (0, utils_1.createParser)(defs.CodecType.Record, (codec, options) => {
    return {
        type: 'object',
        additionalProperties: root.RootParser(codec.props.type, options),
        properties: {},
        required: []
    };
});
