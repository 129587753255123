"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodecType = void 0;
var CodecType;
(function (CodecType) {
    CodecType["String"] = "string";
    CodecType["Number"] = "number";
    CodecType["Boolean"] = "boolean";
    CodecType["Literal"] = "literal";
    CodecType["Enum"] = "enum";
    CodecType["Null"] = "null";
    CodecType["Any"] = "any";
    CodecType["Optional"] = "optional";
    CodecType["Object"] = "object";
    CodecType["Record"] = "record";
    CodecType["Array"] = "array";
    CodecType["Tuple"] = "tuple";
    CodecType["Recursive"] = "recursive";
    CodecType["Union"] = "union";
    CodecType["Intersection"] = "intersection";
})(CodecType = exports.CodecType || (exports.CodecType = {}));
