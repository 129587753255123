"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createParser = void 0;
const createParser = (tag, parser) => {
    return {
        tag,
        parse: parser
    };
};
exports.createParser = createParser;
