"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.record = exports.object = void 0;
const defs = require("../definitions");
const utils = require("../utils");
const codec_1 = require("./codec");
const objectAssertion = (data) => {
    if (Array.isArray(data)) {
        throw new utils.TransformError('Expected a map but got an array');
    }
    if (typeof data !== 'object') {
        throw new utils.TransformError(`Expected a map but got ${typeof data}`);
    }
};
const object = (shape) => {
    const entries = Object.entries(shape);
    const transformer = (transformer) => (data) => {
        objectAssertion(data);
        return entries.reduce((acc, [key, codec]) => {
            const transformed = codec[transformer](data[key]);
            if (transformed !== undefined) {
                acc[key] = transformed;
            }
            return acc;
        }, {});
    };
    return (0, codec_1.codec)(defs.CodecType.Object, transformer('encode'), transformer('decode'), {
        shape
    });
};
exports.object = object;
const record = (type) => {
    const transformer = (transformer) => (data) => {
        objectAssertion(data);
        return Object.entries(data).reduce((acc, [key, value]) => {
            const transformed = type[transformer](value);
            if (transformed !== undefined) {
                acc[key] = transformed;
            }
            return acc;
        }, {});
    };
    return (0, codec_1.codec)(defs.CodecType.Record, transformer('encode'), transformer('decode'), {
        type
    });
};
exports.record = record;
