"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiteralParser = exports.EnumParser = exports.AnyParser = exports.NullParser = exports.BooleanParser = exports.NumberParser = exports.StringParser = exports.createPrimitiveParser = void 0;
const defs = require("../../definitions");
const utils_1 = require("../utils");
const createPrimitiveParser = (type) => {
    return (0, utils_1.createParser)(type, () => {
        return { type };
    });
};
exports.createPrimitiveParser = createPrimitiveParser;
exports.StringParser = (0, exports.createPrimitiveParser)(defs.CodecType.String);
exports.NumberParser = (0, exports.createPrimitiveParser)(defs.CodecType.Number);
exports.BooleanParser = (0, exports.createPrimitiveParser)(defs.CodecType.Boolean);
exports.NullParser = (0, exports.createPrimitiveParser)(defs.CodecType.Null);
exports.AnyParser = (0, utils_1.createParser)(defs.CodecType.Any, () => {
    return {};
});
exports.EnumParser = (0, utils_1.createParser)(defs.CodecType.Enum, (codec) => {
    return {
        type: 'string',
        enum: Object.values(codec.props.enum)
    };
});
exports.LiteralParser = (0, utils_1.createParser)(defs.CodecType.Literal, (codec) => {
    return {
        type: typeof codec.props.value,
        const: codec.props.value
    };
});
